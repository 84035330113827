import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../../components/Layout";
import PostContent from "../../components/PostContent";
import * as styles from "./{strapiPost.slug}.module.css"
import SEO from "../../components/SEO";
const PostPage = (props) => {
  const post = props.data.strapiPost;
  return (
    <Layout>
      <SEO title={post.title} description={post.title} />
      <div className={styles.container}>
        <PostContent post={post} />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query($id: String!){
    strapiPost(id: {eq:$id }){
      id
      title
      slug
      video
      description
      thumbnail
      published_at(formatString: "YYYY年MM月DD日 hh:mm")
      category {
        name
        viewName
        slug
      }
    }
  }
`

export default PostPage;
