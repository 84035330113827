import React, { useEffect } from 'react'
import { useLocation } from "@reach/router"
import Cusdis from 'gatsby-plugin-cusdis'

const Comment = ({title,id}) => {
    const location = useLocation();
  
    const cusdisAttrs = {
      pageId: id,
      pageUrl: `http://localhost:8000${location.pathname}`||`https://surprise-movie.party${location.pathname}`||`https://surprise-movie.dev${location.pathname}`,
      pageTitle: title,
    }
    return(
<Cusdis attrs={cusdisAttrs,{ theme: 'dark', }} />
    )
}

export default Comment;

