import React, { useEffect } from 'react'
import * as styles from "./PostContent.module.css";
import {Button} from"react-bootstrap";
import { Link } from "gatsby";
import { useLocation } from "@reach/router";
import Comment from"../pages/posts/Comment";
import SEO from './SEO';

const PostContent = (props) => {
  const { title, video, category, published_at, thumbnail ,id,description} = props.post;
  const location = useLocation();

 
  return (
    <article>
      <SEO title={title} description={description} />
    

      <div className={styles.container}>
      <time className={styles.publishedAt}>{published_at}</time>

      <div className={styles.title}>{title}</div>
      <div className={styles.thumbnailWrapper}>
      <img src={thumbnail} alt={title} className={styles.thumbnail}/>

      </div>
      <div className={styles.linkButtonContainer}>

    <Link to={`/categories/${category.slug}` } className={styles.link}>
    <Button className="categoryButton"variant='warning' style={{padding:`5px`,fontSize:`16px`,margin:`5px`}}>{category.viewName}</Button></Link>
        
 
 </div>
    
      <div
        dangerouslySetInnerHTML={{ __html: video }
      
      }className={styles.content}
      
      />

            <div className={styles.description}>{description}</div>

            <div className={styles.cusdisContainer}>
              <p>Comment</p>
      <Comment title={title} id={id}/>

      </div>
</div>
    </article>
  );
};

export default PostContent;
