// extracted by mini-css-extract-plugin
export var article = "PostContent-module--article--A7k2K";
export var container = "PostContent-module--container--KBM0J";
export var description = "PostContent-module--description--vKV6Z";
export var title = "PostContent-module--title--ZUuif";
export var thumbnailWrapper = "PostContent-module--thumbnailWrapper--RRNrT";
export var thumbnail = "PostContent-module--thumbnail--mVZGH";
export var content = "PostContent-module--content--POe0E";
export var linkButtonContainer = "PostContent-module--linkButtonContainer--FMk3u";
export var link = "PostContent-module--link--h6EnY";
export var publishedAt = "PostContent-module--publishedAt--ktdJb";
export var cusdisContainer = "PostContent-module--cusdisContainer--Dytfk";